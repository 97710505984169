import appConfig from "@/config";
import {getCsrfToken} from "@/utility/helper";

export const fetchContractUs = async (attribute: any) => {
  return await fetch(`${appConfig.apiEndPoint}/contact-us`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
    body: JSON.stringify(attribute)
  })
}